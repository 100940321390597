#ResumeContainer {
    margin: auto;
    width: 100%;
    /* border: yellow solid 3px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PDFPage {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    /* border: red solid 3px; */
}

.PDFPageOne {
    margin-bottom: 10px;
}

.PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
}
@font-face {
    font-family: Comfortaa;
    src: url("../fonts/Comfortaa.ttf")
  }
 /* 
  * {
    font-family: Comfortaa;
  }
  */
  body{
      margin: 0 !important
  }
.ex{
    transition-delay: 0s;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}
.ant-form-item-explain{
    margin: 0px !important;
}
/* INPUTS */
input[type='password'] {
    color: inherit !important;
}
input[type='text'] {
    color: inherit !important;
}
.ant-input-password-icon {
    color: inherit !important;
}
.ant-input[disabled] {
    color: inherit !important;
}
/* LABEL */
.ant-form-small .ant-form-item-label > label {
    height: auto !important;
}
/* SELECTS */
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: inherit !important;
}
.ant-select-arrow {
    color: inherit !important;
}
.ant-select-selector{
    border-radius: 8px !important;
    text-align: left !important;
}
/* MODAL */
.ant-modal-content{
    border-radius:8px !important;
}
.ant-modal-header{
    border-radius: 8px 8px 0 0 !important;
}
.ant-modal-footer button {
    border-radius:8px !important;
}
/* BUTTON MODAL */
.ant-modal-confirm-btns .ant-btn{
    border-radius: 8px !important;
}
/* Tooltip */
.ant-tooltip{
    background-color: rgba(0, 0, 0, 0) !important;
}
.ant-tooltip-inner{
    background-color: rgba(0, 0, 0, 1) !important;
    text-align: center !important;
}
/* SIDEBAR */
.ant-layout-sider-children{
    width: 100% !important;
}

/* FORM 
.ant-form-item{
    line-height: 3 !important;
}
*/
/* INPUT */
.ant-form-item-explain{
    display: flex !important;
    text-align: justify !important;
}
/* INPUT SEARCH */
.ant-input-search .ant-input-group .ant-input{
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}
.ant-input-search .ant-input-group .ant-input-group-addon .ant-input-search-button{
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

/* INPUT NUMBER */
.ant-input-number-input{
    text-align: end !important;
    padding-right: 30px !important;
}

/* Notifications */
.message-context-notification > .ant-notification-notice-close{
    display: none;
}
.message-context-notification .ant-notification-notice-message{
    display: none;
}
.ant-notification-notice{
    border-radius: 8px !important;
}
/* TABLE */
.ant-table-tbody{
    background-color: white !important;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
    background: #e3f2fd !important;
}
/* BADGE */
.ant-badge-count{
    z-index: 999 !important;
}
/* TREE */
.ant-tree{
    background-color: white !important;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected{
    background-color: #e3f2fd !important;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle{
   
}
/* TAG */
.ant-tag{
    border-radius: 8px !important;
    width: min-content !important;
}


/* UPLOAD */
.ant-upload-picture-card-wrapper {
    width: inherit !important;
}

/* LIST */
.ant-list-split .ant-list-item {
    border-bottom: 1px solid #f0f0f0 !important;
}

.ant-popover-inner-content{
    display: flex;
}

/* PRINT OUTRA LIBRARY */

@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      /*-webkit-print-color-adjust: exact;*/
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page {
    size: auto;
    margin: 10mm;
  }
#qr-code-reader section {
  height: calc(100vh - 115px);
}

#qr-code-reader section div {
  height: 85vh !important;

  box-shadow: rgba(255, 255, 255, 0.8) 0px 0px 0px 5px inset !important;
  border-color: rgba(0, 0, 0, 0.4) !important;
  border-width: 120px 50px 120px 50px !important;
}

#qr-code-reader .bar-container{
  width: 100%;
  height: calc(100vh - 115px);
  position: absolute;
  z-index: 100;
}

#qr-code-reader .bar-container .bar-content{
  width: 100%;
  /* height: -webkit-fill-available; */
  height: 300px;
  border: 5px solid #fff;
  overflow: hidden;
}

#qr-code-reader .bar-container .bar-content .bar{
  background-color: #fff;
  width: 100%;
  height: 5px;
  animation: BarMovement 2.5s;
  animation-iteration-count:infinite;
}

@keyframes BarMovement {
  0% { margin-top: 0%; }
  100% { margin-top: 200%; }
}